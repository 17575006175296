// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-promoted-js": () => import("./../src/templates/promoted.js" /* webpackChunkName: "component---src-templates-promoted-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bringcomforthome-js": () => import("./../src/pages/bringcomforthome.js" /* webpackChunkName: "component---src-pages-bringcomforthome-js" */),
  "component---src-pages-coupon-js": () => import("./../src/pages/coupon.js" /* webpackChunkName: "component---src-pages-coupon-js" */),
  "component---src-pages-genevieve-gorder-collection-js": () => import("./../src/pages/GenevieveGorderCollection.js" /* webpackChunkName: "component---src-pages-genevieve-gorder-collection-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patriotscoupon-js": () => import("./../src/pages/patriotscoupon.js" /* webpackChunkName: "component---src-pages-patriotscoupon-js" */),
  "component---src-pages-patriotssweeps-js": () => import("./../src/pages/patriotssweeps.js" /* webpackChunkName: "component---src-pages-patriotssweeps-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-scotties-helps-js": () => import("./../src/pages/scotties-helps.js" /* webpackChunkName: "component---src-pages-scotties-helps-js" */),
  "component---src-pages-tscoupon-js": () => import("./../src/pages/tscoupon.js" /* webpackChunkName: "component---src-pages-tscoupon-js" */),
  "component---src-pages-user-agreement-js": () => import("./../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */)
}

